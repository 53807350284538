import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import "./button.scss";

const Button = ({
	className,
	onClick,
	children,
	submit = false,
	variant = "default",
	loading = false,
	loadingLabel,
	disabled = false,
	...props
}) => {
	const buttonClass = classNames("button", className, `button--${variant}`, {
		"button--loading": loading,
		"button--disabled": disabled,
	});

	const loadingMessage = loadingLabel ? (
		loadingLabel
	) : (
		<FormattedMessage id="general.button.loading.label" />
	);

	const buttonLabel = loading ? loadingMessage : children;

	return (
		// eslint-disable-next-line react/button-has-type
		<button
			type={submit ? "submit" : "button"}
			className={buttonClass}
			onClick={onClick}
			disabled={disabled || loading}
			{...props}
		>
			<div className={`button__text`}>{buttonLabel}</div>
		</button>
	);
};

Button.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,
	submit: PropTypes.bool,
	disabled: PropTypes.bool,
	loading: PropTypes.bool,
	loadingLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	variant: PropTypes.oneOf(["primary", "secondary"]),
};

export default Button;
