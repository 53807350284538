import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import RelativeLink from "app/pages/.shared/RelativeLink";
import NewBadge from "app/pages/Header/conditionnalComponents/NewBadge";
import { brandPropTypes } from "app/utils/propTypes";
import { BRANDS } from "app/constants";
import DotNotification from "app/pages/.shared/DotNotification/DotNotification";
import IconAccount from "app/pages/.shared/IconAccount/IconAccount";
import { memo } from "react";
import SmartDPFooterMenu from "app/pages/SmartDP/SmartDPHeader/SmartDPFooterMenu";
import "./SmartDPMenuAuthenticated.scss";

const SmartDPMenuAuthenticated = ({
	enableSponsorship,
	logout = () => {},
	partnerCode,
	brand,
	showCustomerRequestNotification,
	onItemClick = () => {},
	isWhiteLabel,
}) => {
	const isBrandXX = brand === BRANDS.XX;

	return (
		<div className="smartdp-menu-authenticated">
			<nav className="account-menu">
				<div className="account-menu__global-message">
					<IconAccount userIsConnected={true} className="icon-account-menu-icon" />

					<h3 className="account-menu__global-message-label">
						<FormattedMessage id={"general.hello.message.label"} />
					</h3>
				</div>

				<ul className="account-menu__list">
					{!isBrandXX && (
						<li className="account-menu__item">
							<RelativeLink
								to={{ pathname: "/account/profile" }}
								onClick={onItemClick}
								data-testid="account-menu-profile-item"
							>
								<FormattedMessage id="menu.item.my.profile" />
							</RelativeLink>
						</li>
					)}

					{isWhiteLabel && !isBrandXX && (
						<li className="account-menu__item">
							<RelativeLink
								to={{ pathname: "/account/subscriptions" }}
								onClick={onItemClick}
								data-testid="account-menu-subscriptions-item"
							>
								<FormattedMessage id="menu.item.my.subscriptions" />
							</RelativeLink>
						</li>
					)}

					{enableSponsorship && (
						<li className="account-menu__item">
							<RelativeLink
								to={{ pathname: "/account/sponsorship" }}
								onClick={onItemClick}
								data-testid="account-menu-sponsorship-item"
							>
								<FormattedMessage id="menu.item.sponsorship" />
								<NewBadge partnerCode={partnerCode} />
							</RelativeLink>
						</li>
					)}

					{!isBrandXX && (
						<li className="account-menu__item">
							<RelativeLink
								to={{ pathname: "/account/mybookings" }}
								onClick={onItemClick}
								data-testid="account-menu-mybookings-item"
							>
								<FormattedMessage id="menu.item.my.bookings" />
								{showCustomerRequestNotification && <DotNotification />}
							</RelativeLink>
						</li>
					)}

					{isWhiteLabel && !isBrandXX && (
						<li className="account-menu__item">
							<RelativeLink
								to={{ pathname: "/account/security" }}
								onClick={onItemClick}
								data-testid="account-menu-security-item"
							>
								<FormattedMessage id="menu.item.security" />
							</RelativeLink>
						</li>
					)}

					<li className="account-menu__item">
						<RelativeLink
							to={{ pathname: "/" }}
							onClick={logout}
							data-testid="account-menu-logout-item"
						>
							<FormattedMessage id="menu.item.logout" />
						</RelativeLink>
					</li>
				</ul>
			</nav>

			<SmartDPFooterMenu onItemClick={onItemClick} />
		</div>
	);
};

SmartDPMenuAuthenticated.propTypes = {
	enableSponsorship: PropTypes.bool,
	logout: PropTypes.func,
	partnerCode: PropTypes.string,
	brand: brandPropTypes,
	showCustomerRequestNotification: PropTypes.bool,
	onItemClick: PropTypes.func,
	isWhiteLabel: PropTypes.bool,
};

export default memo(SmartDPMenuAuthenticated);
