import { memo } from "react";
import IconCb from "app/pages/.shared/static/icons/IconCb";
import IconMastercard from "app/pages/.shared/static/icons/IconMastercard";
import IconVisa from "app/pages/.shared/static/icons/IconVisa";
import IconAmex from "app/pages/.shared/static/icons/IconAmex";
import IconPaiement2x from "app/pages/.shared/static/icons/IconPaiement2x";
import IconPaiement4x from "app/pages/.shared/static/icons/IconPaiement4x";
import IconPaiement10x from "app/pages/.shared/static/icons/IconPaiement10x";
import IconPaypal from "app/pages/.shared/static/icons/IconPaypal";
import "./ReassurancePayment.scss";
import PropTypes from "prop-types";
import { CREDIT_CARDS_TYPES, PAYMENT_METHODS } from "app/constants";

const IconByTerms = {
	2: IconPaiement2x,
	4: IconPaiement4x,
	10: IconPaiement10x,
};

const ReassurancePayment = ({ paymentMethods }) => {
	const allUniquePaymentMethods = [
		...new Set(
			paymentMethods?.flatMap(
				paymentMethod => paymentMethod.constraints.acceptedCreditCardTypes
			)
		),
	];

	const isPaypal = paymentMethods?.find(
		paymentMethod => paymentMethod.code === PAYMENT_METHODS.PAYPAL
	);

	let AdditionalIcons = [];
	paymentMethods.forEach(paymentMethod =>
		paymentMethod?.termsChoices?.forEach(term => {
			if (term > 1) {
				AdditionalIcons.push(IconByTerms[term]);
			}
		})
	);

	return (
		<div className="reassurance-payment">
			<div className="reassurance-payment__payment">
				{allUniquePaymentMethods.map(code => {
					switch (code) {
						case CREDIT_CARDS_TYPES.CB:
							return <IconCb key={code} width={35} height={15} />;
						case CREDIT_CARDS_TYPES.MASTERCARD:
							return <IconMastercard key={code} width={40} height={25} />;
						case CREDIT_CARDS_TYPES.VISA:
							return <IconVisa key={code} width={40} height={15} />;
						case CREDIT_CARDS_TYPES.AMEX:
							return <IconAmex key={code} width={25} height={25} />;
						default:
							return null;
					}
				})}
				{isPaypal && <IconPaypal width={25} height={25} />}
				{AdditionalIcons.map((AdditionalIcon, index) => (
					<AdditionalIcon key={index} width={30} height={30} />
				))}
			</div>
		</div>
	);
};

ReassurancePayment.propTypes = {
	paymentMethods: PropTypes.array,
};

ReassurancePayment.defaultProps = {
	paymentMethods: [],
};

export default memo(ReassurancePayment);
