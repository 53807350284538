import { connect } from "react-redux";
import {
	calculateInboundArrivalDayCount,
	calculateOutboundArrivalDayCount,
} from "app/pages/Booking/bookingSelectors";
import TravelDates from "app/pages/.shared/TravelDates/TravelDates";

const mapStateToProps = (state, ownProps) => {
	const currentMerchCode = state.merchandising?.current?.code;
	let booking = state.booking;

	if (!ownProps.isHolidaySummary) {
		if (currentMerchCode) {
			booking = state.booking.smartDPMerch;
		}
	}

	return {
		booking,
		inboundArrivalDayCount: calculateInboundArrivalDayCount(state),
		outboundArrivalDayCount: calculateOutboundArrivalDayCount(state),
	};
};

export default connect(mapStateToProps)(TravelDates);
