import "./LogoBrand.scss";
import { connect } from "react-redux";
import LogoBrand from "app/pages/.shared/LogoBrand/LogoBrand";
import { getTheme } from "app/reducers/partnerSelector";

const mapStateToProps = state => {
	return {
		shop: state.shop,
		brand: state.brand.code,
		theme: getTheme(state),
	};
};

export default connect(mapStateToProps)(LogoBrand);
