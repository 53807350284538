import PropTypes from "prop-types";
import { memo, useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import "./RelativeLink.scss";
import { isAbsoluteUrl } from "app/utils/urlDataExtractor";

const RelativeLink = ({
	to = {},
	className,
	variant = "default",
	children,
	enableLoading,
	locationState,
	loadingLabel,
	disabled,
	target,
	rel,
	onClick,
	...props
}) => {
	const [loading, setLoading] = useState(false);

	const onLinkClick = useCallback(
		event => {
			if (enableLoading) {
				setLoading(true);
			}

			if (onClick) {
				onClick(event);
			}
		},
		[enableLoading]
	);

	const loadingLabelNode = loadingLabel ? (
		loadingLabel
	) : (
		<FormattedMessage id="general.button.loading.label" />
	);
	const buttonLabel = loading && enableLoading ? loadingLabelNode : children;

	const linkClassName = classNames({
		"relative-link": true,
		[`relative-link--${variant}`]: true,
		[className]: className,
		"relative-link--disabled": disabled,
	});

	const locationDescriptor = {
		...to,
		pathname: to.pathname,
		state: locationState,
	};

	return isAbsoluteUrl(to.pathname) ? (
		<a
			className={linkClassName}
			href={to.pathname}
			target={target}
			rel={rel}
			{...props}
			onClick={onLinkClick}
		>
			<div className="relative-link__loader" />
			<div className="relative-link__text">{buttonLabel}</div>
		</a>
	) : (
		<NavLink
			className={linkClassName}
			to={locationDescriptor}
			target={target}
			rel={rel}
			{...props}
			onClick={onLinkClick}
		>
			<div className="relative-link__loader" />
			<div className="relative-link__text">{buttonLabel}</div>
		</NavLink>
	);
};

RelativeLink.propTypes = {
	loadingLabel: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
	enableLoading: PropTypes.bool,
	disabled: PropTypes.bool,
	locationState: PropTypes.object,
	to: PropTypes.object.isRequired,
	variant: PropTypes.oneOf(["naked", "button", "primary", "secondary"]),
	onClick: PropTypes.func,
	rel: PropTypes.string,
	target: PropTypes.string,
	"data-testid": PropTypes.string,
};

export default memo(RelativeLink);
