import { connect } from "react-redux";
import SignupMenuAccount from "app/pages/.shared/SmartDPMenuUnauthenticated/SmartDPMenuUnauthenticated";
import { getUUID } from "app/pages/Auth/authSelectors";
import { getMenuConnectMessages } from "app/reducers/partnerSelector";

const mapStateToProps = state => {
	return {
		isIdentified: Boolean(getUUID(state)),
		menuConnectMessages: getMenuConnectMessages(state),
	};
};

export default connect(mapStateToProps)(SignupMenuAccount);
