import { memo, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import AlertMessage from "app/pages/.shared/AlertMessage";
import { scrollToElement } from "app/utils/scroller";
import { ALERT_TYPE } from "app/constants";
import { FormattedMessage } from "react-intl";
import { isEmpty } from "lodash";
import { clean } from "fast-clean";
import { deepMappingError, getFieldErrorNames } from "app/pages/.shared/form/formikUtils";

/**
 * Display formik errors list under a message in an alert message bloc
 * It must be placed inside Formik component.
 * @param message error message to display on top of the validation errors list. If message is not provided as prop, a default one is displayed
 * @param fieldsFilter array of field names. if fieldsFilter include the first errors of formik, we scroll to the first FormErrorMessages
 * @param disableErrorsDetails if true, list of validation errors is not display under the message
 * @returns {JSX.Element|boolean}
 */
const FormErrorMessages = ({
	message,
	isSubmitting,
	errors = {},
	fieldsFilter = [],
	disableErrorsDetails = false,
}) => {
	const computedErrors = clean(errors); // clean object error or array error empty
	if (!isEmpty(computedErrors) && computedErrors._error) {
		delete computedErrors._error;
	}

	const fieldErrorNames = getFieldErrorNames(computedErrors);
	const scrollable =
		fieldErrorNames[0] &&
		(fieldsFilter.length === 0 || fieldsFilter.includes(fieldErrorNames[0]));
	const showError =
		fieldsFilter.length > 0
			? fieldsFilter.some(field => computedErrors[field])
			: !isEmpty(computedErrors);
	const ref = useRef();
	let errorsList = [];
	if (!isEmpty(computedErrors)) {
		if (fieldsFilter.length === 0) {
			deepMappingError(computedErrors, errorsList);
		} else {
			fieldsFilter.forEach(field => {
				deepMappingError(computedErrors[field], errorsList);
			});
		}
	}

	const defautMessage = <FormattedMessage {...{ id: "error.form.generic" }} />;

	useEffect(() => {
		if (!isSubmitting && scrollable) {
			scrollToElement({ element: ref.current, offset: -100, disableSmoothScroll: false });
		}
	}, [isSubmitting, ref.current]);

	if (!showError) {
		return false;
	}

	return (
		<div ref={ref} data-testid="global-error-message">
			<AlertMessage
				{...errorsList.length > 0 && !disableErrorsDetails && { errors: errorsList }}
				message={message || defautMessage}
				alertType={ALERT_TYPE.ERROR}
			/>
		</div>
	);
};

FormErrorMessages.propTypes = {
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	fieldsFilter: PropTypes.array,
	disableErrorsDetails: PropTypes.bool,
	errors: PropTypes.object,
	isSubmitting: PropTypes.bool,
};

export default memo(FormErrorMessages);
