import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getPartnerCode } from "app/reducers/partnerSelector";
import { getOpenCustomerRequestsCount } from "app/pages/Account/MyBookings/myBookingsSelector";
import SmartDPMenuAuthenticated from "app/pages/.shared/SmartDPMenuAuthenticated/SmartDPMenuAuthenticated";
import { logout } from "app/pages/Auth/AuthActions";
import { isWhiteLabel } from "app/reducers/brandSelector";

const mapStateToProps = state => {
	return {
		brand: state.brand,
		enableSponsorship: state.partner.enableSponsorship,
		partnerCode: getPartnerCode(state),
		isWhiteLabel: isWhiteLabel(state),
		showCustomerRequestNotification: getOpenCustomerRequestsCount(state) > 0,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ logout }, dispatch);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SmartDPMenuAuthenticated);
