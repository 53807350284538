import { Children, cloneElement, memo, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { animated, useTransition } from "react-spring";
import classNames from "classnames";
import "./SidePanel.scss";
import useBlockViewportScroll from "app/pages/.shared/useBlockViewportScroll";
import * as ReactDOM from "react-dom";

const SidePanel = ({
	children,
	isOpen,
	onClose,
	direction,
	disableBlockViewPortScroll,
	className,
}) => {
	const [isVisible, toogleVisibility] = useState(isOpen);

	const isRTL = direction === "rtl";

	const transitions = useTransition(isVisible, null, {
		from: { transform: isRTL ? "translateX(100%)" : "translateX(-100%)" },
		enter: { transform: "translateX(0px)" },
		leave: { transform: isRTL ? "translateX(100%)" : "translateX(-100%)" },
		option: {
			delay: 75,
			duration: 200,
		},
	});

	useBlockViewportScroll(isOpen && !disableBlockViewPortScroll);

	useEffect(() => {
		toogleVisibility(isOpen);
	}, [isOpen]);

	const hideSidePanel = useCallback(() => {
		toogleVisibility(false);
		onClose();
	}, []);

	const asideClass = classNames(
		{
			"side-panel": true,
			"side-panel--rtl": direction === "rtl",
			"side-panel--pushed": isVisible,
		},
		className
	);

	const childrenWithProps = Children.map(children, child => {
		return cloneElement(child, {
			hideSidePanel,
			isVisible,
		});
	});

	return (
		isVisible &&
		ReactDOM.createPortal(
			transitions.map(({ item, key, props }) =>
				item ? (
					<animated.div style={props} key={key} className={asideClass}>
						{childrenWithProps}
					</animated.div>
				) : null
			),
			document.getElementById("perfectstay")
		)
	);
};

SidePanel.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	direction: PropTypes.oneOf(["rtl", "ltr"]),
	className: PropTypes.string,
};

SidePanel.defaultProps = {
	onClose: () => {},
	direction: "rtl",
};

export default memo(SidePanel);
