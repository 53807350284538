import { memo } from "react";
import PropTypes from "prop-types";
import IconAwesomeLock from "app/pages/.shared/IconAwesomeLock";
import { FormattedMessage } from "react-intl";
import RelativeLink from "app/pages/.shared/RelativeLink";
import "./SmartDPMenuUnauthenticated.scss";
import { useLocation } from "react-router-dom";
import { IconHelp, IconLuggage } from "app/pages/.shared/static/icons";
import {
	sendTagOnMenuGoToAuth,
	sendTagOnMenuGoToHelp,
	sendTagOnMenuGoToSearchBooking,
} from "app/utils/analytics";
import IconUnlock from "app/pages/.shared/IconAwesomeUnlock";
import Paragraphs from "app/pages/.shared/Paragraphs";
import IconPictoChevron from "app/pages/.shared/IconPictoChevron";

const SmartDPMenuUnauthenticated = ({
	onItemClick = () => {},
	isIdentified,
	menuConnectMessages = {},
}) => {
	const location = useLocation();
	return (
		<div className="signup-menu-account">
			{menuConnectMessages?.visitorMessage ? (
				<div className="signup-menu-account__dynamic-message">
					<Paragraphs
						paragraphs={menuConnectMessages?.visitorMessage}
						enableHTMLFormatting
					/>
					<RelativeLink
						to={{
							pathname: "/booking-auth",
							search: `?onauthsuccess=${encodeURIComponent(
								location.pathname + location.search
							)}`,
						}}
						data-testid="go-to-booking-auth-link"
						onClick={() => {
							sendTagOnMenuGoToAuth();
							onItemClick();
						}}
					>
						<div className="signup-menu-account__signup-login-button">
							<FormattedMessage id="menu.mobile.register.login" />
							<IconPictoChevron />
						</div>
					</RelativeLink>
				</div>
			) : (
				<div className="signup-menu-account__signup-intro">
					<IconAwesomeLock height={20} width={20} />
					<FormattedMessage id="menu.mobile.signup.to.flashsales.intro" tagName="h4" />
				</div>
			)}
			<nav className="signup-menu-account__body">
				<ul className="signup-menu-account__nav">
					{isIdentified && (
						<li className="signup-menu-account__nav-item signup-menu-account__nav-item--bold">
							<RelativeLink to={{ pathname: "/listing" }} onClick={onItemClick}>
								<IconUnlock height={20} width={20} />
								<FormattedMessage id="menu.mobile.flashsales" tagName="h4" />
							</RelativeLink>
						</li>
					)}
					{!menuConnectMessages?.visitorMessage && (
						<li className="signup-menu-account__nav-item">
							<RelativeLink
								to={{
									pathname: "/booking-auth",
									search: `?onauthsuccess=${encodeURIComponent(
										location.pathname + location.search
									)}`,
								}}
								data-testid="go-to-booking-auth-link"
								onClick={() => {
									sendTagOnMenuGoToAuth();
									onItemClick();
								}}
							>
								<FormattedMessage id="menu.mobile.login.signup" tagName="h4" />
							</RelativeLink>
						</li>
					)}
					<li className="signup-menu-account__nav-item">
						<RelativeLink
							to={{ pathname: "/booking-search" }}
							onClick={() => {
								sendTagOnMenuGoToSearchBooking();
								onItemClick();
							}}
							data-testid="go-to-booking-search-link"
						>
							<IconLuggage height={20} width={20} />
							<FormattedMessage id="menu.mobile.go.to.my.bookings" tagName="h4" />
						</RelativeLink>
					</li>
					<li className="signup-menu-account__nav-item">
						<RelativeLink
							rel="noopener noreferrer"
							target="_blank"
							to={{ pathname: "/faq" }}
							onClick={() => {
								sendTagOnMenuGoToHelp();
								onItemClick();
							}}
						>
							<IconHelp height={20} width={20} />
							<FormattedMessage id="menu.item.help" tagName="h4" />
						</RelativeLink>
					</li>
				</ul>
			</nav>
		</div>
	);
};

SmartDPMenuUnauthenticated.propTypes = {
	onItemClick: PropTypes.func,
	isIdentified: PropTypes.bool,
	menuConnectMessages: PropTypes.object,
};

export default memo(SmartDPMenuUnauthenticated);
