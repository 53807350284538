import PropTypes from "prop-types";
import { Children, Component } from "react";
import { updateResponsiveState } from "app/pages/.shared/responsive/responsiveActionCreator";
import { connect } from "react-redux";
import { RESOLUTION } from "app/constants";
import { sendTagOnUnmatchedResolution } from "app/utils/analytics";
import { bindActionCreators } from "redux";

class ResponsiveStateProvider extends Component {
	componentDidMount() {
		this.Gridle = require("gridle/js/gridle");

		this.Gridle.on("update", () => {
			const isDefault = this.Gridle.isActive("default");
			const isSmall = this.Gridle.isActive("sm");
			const isMedium = this.Gridle.isActive("md");
			const isLarge = this.Gridle.isActive("lg");
			let clientResolution = RESOLUTION.UNKNOWN;

			if (isDefault === false && isSmall === true && isMedium === true && isLarge === true) {
				clientResolution = RESOLUTION.XLARGE;
			} else if (
				isDefault === false &&
				isSmall === true &&
				isMedium === true &&
				isLarge === false
			) {
				clientResolution = RESOLUTION.LARGE;
			} else if (
				isDefault === false &&
				isSmall === true &&
				isMedium === false &&
				isLarge === false
			) {
				clientResolution = RESOLUTION.MEDIUM;
			} else if (
				isDefault === true &&
				isSmall === false &&
				isMedium === false &&
				isLarge === false
			) {
				clientResolution = RESOLUTION.SMALL;
			}

			this.props.updateResponsiveState(clientResolution);

			if (clientResolution !== this.props.resolution) {
				sendTagOnUnmatchedResolution(clientResolution, this.props.resolution);
			}
		});

		this.Gridle.init({
			debug: false,
		});
	}

	componentWillUnmount() {
		this.Gridle.on("update", undefined);
	}

	render() {
		return Children.only(this.props.children);
	}
}

ResponsiveStateProvider.propTypes = {
	updateResponsiveState: PropTypes.func,
	resolution: PropTypes.string,
};

const mapStateToProps = state => {
	return {
		resolution: state.resolution,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ updateResponsiveState }, dispatch);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ResponsiveStateProvider);
