import PropTypes from "prop-types";
import { Component } from "react";
import classNames from "classnames";
import "./popover.scss";

/**
 * Pas vocation à être portable pour l'instant.
 * Fonctionne seulement si Popover est children de <button class="button">
 */
class Popover extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPopoverOpen: props.isOpen,
		};
		this.closePopover = this.closePopover.bind(this);
	}

	closePopover() {
		this.setState({
			isPopoverOpen: false,
		});

		if (this.props.onClose) {
			this.props.onClose();
		}
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			isPopoverOpen: nextProps.isOpen,
		});
	}

	render() {
		const { children } = this.props;

		const popoverClassName = classNames({
			popover: true,
			"popover--opened": this.state.isPopoverOpen,
		});

		return (
			<div className={popoverClassName}>
				<div className="popover__content" onClick={this.closePopover}>
					<div className="popover__text">{children}</div>
					<div className="popover__close">
						<i className="icon icon--cross" />
					</div>
				</div>
			</div>
		);
	}
}

Popover.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
};

export default Popover;
